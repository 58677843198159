import { Suspense, useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import routes from './routes';
import { usePopup } from './utils/PopupContext';
import { setPopupHandler } from './utils/PopupHandler';
import LoaderFallback from './components/Loader/LoaderFallback';
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import ProtectedRoute from './utils/ProtectedRoutes';
import { useSelector } from 'react-redux';
// import { usePhoenixClickId } from './utils/PhoenixHelper';

function App() {
	const { handlePopupToggle } = usePopup();
	const location = useLocation();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const isAuthenticatedRedux = useSelector((state) => state.auth.token);

	// Retreive Phoenix click_id
	// usePhoenixClickId();

	useEffect(() => {
		setPopupHandler(handlePopupToggle);
		return () => setPopupHandler(null);
	}, [handlePopupToggle]);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('page') === 'register' && !localStorage.getItem('token')) {
			handlePopupToggle('signInSignUp');
		}
	}, []);

	const renderRoutes = (routes, isAuthenticated) => {
		return routes.map(({ path, element, children, protectedRoute }) => (
			<Route
				key={path}
				path={path}
				element={
					protectedRoute ? (
						<ProtectedRoute isAuthenticated={isAuthenticatedRedux || localStorage.getItem('token')}>{element}</ProtectedRoute>
					) : (
						element
					)
				}
			>
				{children && renderRoutes(children, isAuthenticated)}
			</Route>
		));
	};


	ReactGA.initialize([
		{
			trackingId: "G-0B9X0RVW3H",
		},
	]);
	ReactPixel.init("1207392447043568");
	ReactPixel.pageView();

	return (
		<Suspense fallback={<LoaderFallback />}>
			<Routes>{renderRoutes(routes, isAuthenticated)}</Routes>
		</Suspense>
	);
}

export default App;
