import React from 'react';
import classes from './Returnmessage.module.scss';
import error_icon from '../../assets/Icons/error.svg';
import success_icon from '../../assets/Icons/success.svg';
export default function Returnmessage({ type, message }) {
	return (
		message ? (
			<div className={`${classes.return_container} ${classes[type]}`}>
				{type !== 'none' &&
					< img src={type === 'error' ? error_icon : success_icon} className={classes.icon} alt='' loading="lazy" />
				}
				<span className={classes.message}>{message}</span>
			</div>
		) : <></>
	);
}
