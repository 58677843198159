import React, { useState } from 'react';
import classes from './UserContainers.module.scss';
import InputField from '../../components/InputField/InputField';
import black_man from '../../assets/GeneralImages/payment-profile.png';
import info_icon from '../../assets/Icons/info.svg';
import { usePopup } from '../../utils/PopupContext';
import { updateClientAdditionalInfo } from '../../utils/Api';
import ApiClients from '../../utils/ApiClients';
import Loader from '../Loader/Loader';
import { useDispatch } from 'react-redux';
import { getUserAdditionalData } from '../../store/walletSlice';

export default function PaymentProfile({ type, title }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const [bvn, setBvn] = useState('');
    const { closePopup } = usePopup();
    const dispatch = useDispatch();

    const completePayment_Fct = (e) => {
        e.preventDefault();
        let url = updateClientAdditionalInfo(localStorage.getItem('clientId'));
        let dto = [{ key: "BVN", value: bvn },
        { key: "PFName", value: firstName, },
        { key: "PLName", value: lastName }]
            setLoading(true);
            ApiClients.post(url, dto).then((res) => {
                setLoading(false);
                if (res.data.status == 0) {
                    dispatch(getUserAdditionalData());
                    closePopup();
                }

            })
    }

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.auth_body}>
                        <div className={classes.left_section}>
                            <img src={black_man} className={classes.black_man} alt="black man" loading="lazy"/>
                        </div>
                        <form className={classes.right_section} onSubmit={(e) => completePayment_Fct(e)}>
                            <InputField required={true} label="first name" type="text" placeholder="Enter your First Name" inputValueProp={firstName} onChangeInput={setFirstName} />
                            <InputField required={true} label="last name" type="text" placeholder="Enter your Last Name" inputValueProp={lastName} onChangeInput={setLastName} />
                            {/* <InputField required={false} label="bank verification number" type="text" placeholder="Enter your BVN" inputValueProp={bvn} onChangeInput={setBvn} /> */}
                            <div className={classes.action_cnt_sm}>
                                <div className={classes.infocnt}>
                                    <img className={classes.infos_img} src={info_icon} alt='info img' loading="lazy"/> <p className={classes.info_p}>The information provided will be used for all your bank transactions. Ensure all details are accurate and up-to-date to avoid any issues with your transactions.</p>
                                </div>
                                <button className={classes.sign_in_btn} type='submit'>{loading ? <Loader /> : 'confirm'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
