const CONSTANTS = {
    CURRENCY: 'GHS',
    CONFIRMED_18: 'CONFIRMED_18',
    UNVERIFIED_TOKEN: 'UNVERIFIED_TOKEN',
    UNVERIFIED_MSISDN: 'UNVERIFIED_MSISDN',
    TOKEN: 'token',
    MSISDN: 'msisdn',
    USER_DATA: 'USER_DATA',
    BALANCE: 'BALANCE',
    BALANCE_AMOUNT: 'BALANCE_AMOUNT',
    WALLET: 'WALLET',
    FULLNAME: 'fullName',
    USERNAME: 'username',
    OPERATOR: 'OPERATOR',
    CLIENT_ID: 'clientId',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TOKEN_EXPIRY: 'TOKEN_EXPIRY',
    TOKEN_ISSUE_TIME: 'TOKEN_ISSUE_TIME',
    EMAIL: 'EMAIL',
    EMAIL_VERIFIED: 'EMAIL_VERIFIED',
    AVATAR: 'AVATAR',
    BIRTH_DATE: 'BIRTH_DATE',
    MIN_OPAY: 100,
    MAX_OPAY: 100000
}

export default CONSTANTS;