import React, { useState } from 'react';
import classes from './UserContainers.module.scss';
import InputField from '../../components/InputField/InputField';
import ApiClients from '../../utils/ApiClients';
import { usePopup } from '../../utils/PopupContext';
import { updateEmail } from '../../utils/Api';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../store/walletSlice';
import Loader from '../Loader/Loader';
import Returnmessage from '../Returnmessage/Returnmessage';

export default function EditEmail({ type, title }) {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [returnMessage, setReturnMessage] = useState('');
    const { closePopup } = usePopup();
    const dispatch = useDispatch();

    const editEmailFct = (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email) {
            console.error("First name or last name is missing.");
            return;
        }
        ApiClients.post(updateEmail('unlinkEmail', localStorage.getItem('email'), localStorage.getItem('clientId'))).then((res) => {
            if (res.data.status === 0) {
                ApiClients.post(updateEmail('updateEmail', email, localStorage.getItem('clientId')))
                    .then((res) => {
                        setLoading(false);
                        if (res.data.status === 0) {
                            closePopup();
                            dispatch(getUserData());
                        } else {
                            setReturnMessage(res.data.message);
                            setTimeout(() => {
                                setReturnMessage('');
                            }, 3000)
                        }
                    })
                    .catch((err) => {
                        console.error("API call failed:", err);
                    });
            } else {
                setReturnMessage(res.data.message);
                setTimeout(() => {
                    setReturnMessage('');
                }, 3000)
            }
        }).catch((err) => {
            console.error("error")
        })

    };

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>
                    <div className={classes.auth_body}>
                        <form className={classes.right_section} onSubmit={(e) => editEmailFct(e)}>
                            <InputField required={true} label="email" type="email" placeholder="Enter your email" inputValueProp={email} onChangeInput={setEmail} />
                            <div className={classes.action_cnt_sm}>
                                <button className={classes.reset_btn} onClick={() => setEmail('')} type='button'>reset</button>
                                <button className={classes.sign_in_btn + ' ' + classes.save_btn} type='submit'>{loading ? <Loader /> : 'save'}</button>
                            </div>
                        </form>
                    </div>
                    {returnMessage !== '' && <Returnmessage type="error" message={returnMessage} />}
                </div>
            </div>
        </div>
    )
}
