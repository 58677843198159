import { lazy } from "react";
import Articles from "./containers/StaticPages/Articles/Articles";

const About = lazy(() => import("./containers/StaticPages/AboutUs/AbouUs"));
const Terms = lazy(() => import("./containers/StaticPages/Terms/Terms"));
const DrawResults = lazy(() => import("./containers/StaticPages/DrawResults/DrawResults"));
const HowToPlay = lazy(() => import("./containers/StaticPages/HowToPlay/HowToPlay"));
const PayStackDashboard = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/PayStackDashboard"));

const Homepage = lazy(() => import("./containers/Homepage/Homepage"));
const Game = lazy(() => import("./containers/Game/Game"));
const WinEstimation = lazy(() => import("./containers/StaticPages/WinEstimation/WinEstimation"));
const FAQ = lazy(() => import("./containers/StaticPages/Faq/Faq"));
const PrivacyPolicy = lazy(() => import("./containers/StaticPages/PrivacyPolicy/PrivacyPolicy"));
const ContactUs = lazy(() => import("./containers/StaticPages/ContactUs/ContactUs"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Profile = lazy(() => import("./containers/Dashboard/Profile"));
const WalletAndTransaction = lazy(() => import("./containers/Dashboard/WalletAndTransaction"));
const Deposit = lazy(() => import("./containers/Dashboard/Deposit"));
const Withdraw = lazy(() => import("./containers/Dashboard/Withdrawal"));
const Tickets = lazy(() => import("./containers/Dashboard/Tickets"));
const InterswitchDeposit = lazy(() => import("./containers/Dashboard/DepositGateways/Interswitch"));
const OPayDeposit = lazy(() => import("./containers/Dashboard/DepositGateways/OPay/OPayDepositDashboard"));
const OPayDepositBankAccount = lazy(() => import("./containers/Dashboard/DepositGateways/OPay/BankAccount"));
const OPayDepositBankTransfer = lazy(() => import("./containers/Dashboard/DepositGateways/OPay/OPayDepositBankTransfer"));
const OPayDepositExpressCheckout = lazy(() => import("./containers/Dashboard/DepositGateways/OPay/ExpressCheckout"));
const OPayDepositUSSD = lazy(() => import("./containers/Dashboard/DepositGateways/OPay/BankUSSD"));
const OPayDepositWallet = lazy(() => import("./containers/Dashboard/DepositGateways/OPay/OPayDepositWallet"));
const PalmpayDeposit = lazy(() => import("./containers/Dashboard/DepositGateways/PalmpayDeposit"));
const BankAccountPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/BankAccountDeposit"));
const PaymentPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/PaymentPopup"));
const RedirectionPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/RedirectionPopup"));
const UssdPaystack = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/USSDDeposit"));
const TemporaryBankAccount = lazy(() => import("./containers/Dashboard/DepositGateways/PayStack/TemporaryBankAccount"));
const CashoutInterswitch = lazy(() => import("./containers/Dashboard/WithdrawGateways/VerifyWithdraw"));
const CashoutPalmpay = lazy(() => import("./containers/Dashboard/WithdrawGateways/PalmpayWithdraw"));
const CashoutPaystack = lazy(() => import("./containers/Dashboard/WithdrawGateways/Paystack"));
const CashoutOPay = lazy(() => import("./containers/Dashboard/WithdrawGateways/OPay/OPayWithdrawDashboard"));
const CashoutOPayBankTransfer = lazy(() => import("./containers/Dashboard/WithdrawGateways/OPay/OpayWithdrawBankTransfer"));
const CashoutOPayWallet = lazy(() => import("./containers/Dashboard/WithdrawGateways/OPay/OPayWithdrawWallet"));
const routes = [

    {
        path: "/",
        element: <Homepage />,
        children: [],
    },
    {
        path: "/game",
        element: <Game />,
        children: [],
    },
    {
        path: "/winestimation",
        element: <WinEstimation />,
        children: [],
    },
    {
        path: "/faq",
        element: <FAQ />,
        children: [],
    },
    {
        path: "/articles",
        element: <Articles />,
        children: [],
    },
    {
        path: "/privacyPolicy",
        element: <PrivacyPolicy />,
        children: [],
    },
    {
        path: "/contactUs",
        element: <ContactUs />,
        children: [],
    },
    {
        path: "/aboutUs",
        element: <About />,
        children: [],
    },
    {
        path: "/terms",
        element: <Terms />,
        children: [],
    },
    {
        path: "/DrawResults",
        element: <DrawResults />,
        children: [],
    },
    {
        path: "/HowToPlay",
        element: <HowToPlay />,
        children: [],
    },
    {
        path: "/Dashboard",
        element: <Dashboard />,
        protectedRoute: true,
        children: [
            {
                path: "Profile",
                element: <Profile />,
                protectedRoute: true,
            },
            {
                path: "WalletAndTransaction",
                element: <WalletAndTransaction />,
                protectedRoute: true,
            },
            {
                path: "Deposit",
                element: <Deposit />,
                protectedRoute: true,
                children: [{
                    path: "Interswitch",
                    element: <InterswitchDeposit />,
                    protectedRoute: true,
                },{
                    path: "PalmpayDeposit",
                    element: <PalmpayDeposit />,
                    protectedRoute: true,
                }, {
                    path: "Paystack",
                    element: <PayStackDashboard />,
                    protectedRoute: true,
                    children: [
                        {
                            path: "BankDeposit",
                            element: <BankAccountPaystack />,
                            protectedRoute: true,
                        },
                        {
                            path: "temporaryBankAccount",
                            element: <TemporaryBankAccount />,
                            protectedRoute: true,
                        },
                        {
                            path: "PaymentPopup",
                            element: <PaymentPaystack />,
                            protectedRoute: true,
                        },
                        {
                            path: "RedirectionPopup",
                            element: <RedirectionPaystack />,
                            protectedRoute: true,
                        },
                        {
                            path: "UssdDeposit",
                            element: <UssdPaystack />,
                            protectedRoute: true,
                        }
                    ]
                }, {
                    path: "OPay",
                    element: <OPayDeposit />,
                    protectedRoute: true,
                    children: [
                        {
                            path: "OPayDepositBankTransfer",
                            element: <OPayDepositBankTransfer />,
                            protectedRoute: true,
                        },
                        {
                            path: "BankUSSD",
                            element: <OPayDepositUSSD />,
                            protectedRoute: true,
                        },
                        {
                            path: "BankAccount",
                            element: <OPayDepositBankAccount />,
                            protectedRoute: true,
                        },
                        {
                            path: "OPayDepositWallet",
                            element: <OPayDepositWallet />,
                            protectedRoute: true,
                        },
                        {
                            path: "ExpressCheckout",
                            element: <OPayDepositExpressCheckout />,
                            protectedRoute: true,
                        }
                    ]
                }]
            },
            {
                path: "Withdrawal",
                element: <Withdraw />,
                protectedRoute: true,
                children: [{
                    path: "Interswitch",
                    element: <CashoutInterswitch />,
                    protectedRoute: true,
                },{
                    path: "PalmpayWithdraw",
                    element: <CashoutPalmpay/>,
                },{
                    path: "Paystack",
                    element: <CashoutPaystack />,
                    protectedRoute: true,
                },{
                    path: "OPay",
                    element: <CashoutOPay />,
                    protectedRoute: true,
                    children: [
                        {
                            path: "OPayWithdrawBankTransfer",
                            element: <CashoutOPayBankTransfer />,
                            protectedRoute: true,
                        },
                        {
                            path: "OPayWithdrawWallet",
                            element: <CashoutOPayWallet />,
                            protectedRoute: true,
                        }
                    ]
                }]
            },
            {
                path: "Tickets",
                element: <Tickets />,
                protectedRoute: true
            },
        ],
    },

];

export default routes;