import React from "react";
import classes from "./Loader.module.scss";
import moment from "moment";
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Returnmessage from "../Returnmessage/Returnmessage";

const { REACT_APP_CURRENCY } = process.env;

export default function Loader({ type }) {
	return (
		<div className={`${classes.loader_container} ${classes[type]}`} />
	);
}

export function ErrorMessage(message) {
	return (
		<div className={`${classes.message_container} ${classes.error}`}>
			{message ? message : 'Error retrieving data!'}
		</div>
	);
}

export function SuccessMessage(message, contact) {

	return (
		<div className={`${classes.message_container} ${classes.success} ${contact === 'contact' ? classes.contact : ''}`}>
			{message ? message : 'Success!'}
		</div>
	);
}

export function SuccessMessageDeposit(response, message) {

	return (
		<div className={`${classes.deposit_container}`}>
			{response.message ? <p className={classes.centerMessage}>{response.message}</p> : ''}
			{message ? <p className={classes.title}>{message}</p> : ''}
			{response.transferAccountNumber ?
				<p>
					<span className={classes.subTitle}>Account Number:</span>
					{response.transferAccountNumber}
					<button className={classes.copyContainer} onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(response.transferAccountNumber); }}>
						<FontAwesomeIcon icon={faCopy} className={classes.copyIcon} />Copy
					</button>
				</p>
				: ''}
			{response.ussdShortCode ? <p><span className={classes.subTitle}>USSD shortcut:</span> {response.ussdShortCode} <button className={classes.copyContainer} onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(response.ussdShortCode); }}><FontAwesomeIcon icon={faCopy} className={classes.copyIcon} />Copy</button></p> : ''}
			{response.amount ? <p><span className={classes.subTitle}>Amount:</span> {response.amount}{REACT_APP_CURRENCY}</p> : ''}
			{response.transferBankName ? <p><span className={classes.subTitle}>Bank Name: </span> {response.transferBankName} <button className={classes.copyContainer} onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(response.transferBankName); }}><FontAwesomeIcon icon={faCopy} className={classes.copyIcon} />Copy</button></p> : ''}
			{response.expiredTimestamp ? <p><span className={classes.subTitle}>Expiry Date:</span> {moment(response.expiredTimestamp * 1000).format('hh:mm:ss DD-MM-YYYY')}</p> : ''}
			{response.transactionStatus ? <p><span className={classes.subTitle}>Transaction Status:</span> {response.transactionStatus}</p> : ''}
		</div>
	);
}

export function QRCodeContainer(response, message) {
	return (
		<div className={classes.qrCode_container}>
			<p className={classes.centerMessage}>{response.message}</p>
			<p className={classes.title}>{message}</p>
			{response.qrCode ? <QRCode size={128} value={response.qrCode} /> : <Returnmessage type='error' message='QR CODE NOT AVAILABLE!' />}
		</div>
	)
}

