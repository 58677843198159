import React, { Fragment } from 'react'
import classes from './Footer.module.scss';
import logo from '../../assets/Logos/mrWins-logo.svg';
import insta_icon from '../../assets/Icons/insta.svg';
import facebook_icon from '../../assets/Icons/facebook.svg';
import twitter_icon from '../../assets/Icons/twitter.svg';
import nlc_logo from '../../assets/Logos/nlc.png';
import gambleAlertLogo from '../../assets/Logos/gamble-alert-logo.png';
import plus_18 from '../../assets/Logos/plus18.png';
import coins from '../../assets/Account-cards/coins-blurred.png';

import logo_nlrc from '../../assets/Operator/logo_nlrc.png';
import masterCard_logo from '../../assets/Operator/Mastercard-Logo.wine.png';
import paystackLogo from '../../assets/Operator/paystack-logo-D1191C9B92-seeklogo.com.png';
import quickTeller_logo from '../../assets/Operator/quickteller-logo-8BAC5C4C0F-seeklogo.com.png';
import verve_logo from '../../assets/Operator/verve-logo-D5ECC702F3-seeklogo.com.png';
import visaLogo from '../../assets/Operator/VISA-logo-A32D589D31-seeklogo.com.png';


import { Link } from 'react-router-dom';

export default function Footer() {

    const operators = [
        { img: quickTeller_logo, name: 'quickTeller' },
        { img: visaLogo, name: 'visa' },
        { img: paystackLogo, name: 'payStack' },
        { img: verve_logo, name: 'verve' },
        { img: masterCard_logo, name: 'mastereCard' },
        { img: logo_nlrc, name: 'nlrc' },
    ]
    const social_media_object = [
        { media: insta_icon, link: 'https://www.instagram.com/mrwinsnigeria/' },
        { media: facebook_icon, link: 'https://www.facebook.com/profile.php?id=61567844820492' },
        { media: twitter_icon, link: 'https://x.com/MrWinsNigeria' },
    ]

    const links_object = [
        { label: 'home', link: '/' },
        { label: 'how to play', link: '/HowToPlay' },
        { label: 'estimate your wins', link: '/winestimation' },
        { label: 'results', link: '/DrawResults' },
        { label: 'about', link: '/aboutUs' },
        { label: 'contact', link: '/contactUs' },
        { label: 'faqs', link: '/faq' },
        { label: 'privacy policy', link: '/privacyPolicy' },
        { label: 'terms & conditions', link: '/terms' },
    ]


    return (
        <Fragment>
            <footer className={classes.footer}>
                <img src={coins} className={classes.blurried_coins} alt='coins' loading="lazy" />
                <div className='container'>
                    <div className={classes.navigation_cnt}>
                        <div className={classes.section}>
                            <img className={classes.logo} src={logo} alt='logo' loading="lazy" />
                            <div className={classes.social_media_icons}>
                                {social_media_object.map((value, index) => {
                                    return (
                                        <a href={value.link} target='_blank' key={index} className='social-media-cnt'>
                                            <img src={value.media} className={classes.icon} alt='icon' loading="lazy" />
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                        <ul className={classes.section}>
                            {links_object.slice(0, 4).map((value, index) => {
                                return (
                                    <Link to={value.link} className={classes.list_link} key={index}>
                                        <li className={classes.list_item}>{value.label}</li>
                                    </Link>
                                )
                            })}
                        </ul>
                        <div className={classes.separator} />
                        <ul className={classes.section}>
                            {links_object.slice(4, 7).map((value, index) => {
                                return (
                                    <Link to={value.link} className={classes.list_link} key={index}>
                                        <li className={classes.list_item}>{value.label}</li>
                                    </Link>
                                )
                            })}
                        </ul>
                        <div className={classes.separator} />
                        <ul className={classes.section}>
                            {links_object.slice(7, 9).map((value, index) => {
                                return (
                                    <Link to={value.link} className={classes.list_link} key={index}>
                                        <li className={classes.list_item}>{value.label}</li>
                                    </Link>
                                )
                            })}
                        </ul>
                        <div className={classes.section}>
                            <a href='https://gamblealert.org/' target="_blank" rel="noreferrer">
                                <img src={gambleAlertLogo} className={classes.logoaff} alt='Gamble Alert Logo' loading="lazy" />
                            </a>
                            <img src={nlc_logo} className={classes.logoaff} alt='NLC Logo' loading="lazy" />
                            <img src={plus_18} className={classes.logoaff} alt='logo aff' loading="lazy" />
                        </div>
                    </div>
                </div>
            </footer>
            <div className={classes.providers}>
                <div className={classes.providers_cnt}>
                    {operators.map((value, index) => {
                        return (
                            <img key={index} src={value.img} alt='operator_img' className={classes.operator_img} loading="lazy" />
                        )
                    })}
                </div>
                <div className={classes.providers_cnt}>
                    {operators.map((value, index) => {
                        return (
                            <img key={index} src={value.img} alt='operator_img' className={classes.operator_img} loading="lazy" />
                        )
                    })}
                </div>
            </div>
            <div className={classes.copyright_cnt}>
                <span className={classes.copyright}>Copyright © {new Date().getFullYear()} 590 GAMES Nigeria. All Rights Reserved.</span>
            </div>
        </Fragment>
    )
}
