let refreshTokenApi = (clientId) => `/cas/refreshToken?clientId=${clientId}`;
let userLogin = "/cas/login?accessChannel=WEB&format=json&language=en";
let userLogout = "/cas/logout";
let registerUser = "/cas/register";
let walletApi = (clientId) => `/wallet/getBalance?accessChannel=WEB&format=json&language=en&walletType=ALL&clientId=${clientId}`;
let contactUsApi = '/cs/contactUsSendMail';
let validatePromoApi = (promoCode) => `/bonus/validatePromoCode?language=en&promoCode=${promoCode}&type=OnRegister`;
let activeDrawsApi = '/lotto/activeDraws?format=json';
let resendOTP = (msisdn, action) => `/cas/resendOTP?accessChannel=WEB&action=${action}&format=json&language=en&msisdn=${msisdn}`;
let verifyMsisdnApi = '/cas/confirmOTP?format=json&language=en';
let getOperators = 'cas/getOperators?format=JSON&language=en';
let buyTicket = (clientId) => `/lotto/multiSellTicket?clientId=${clientId}&format=json`;
let getBanners = (category, count) => `/web-utility/getBanners?count=${count}&language=en&category=${category}`;
let getArticles = (articleId) => `/web-utility/getArticles?articleId=${articleId}`
let topWinners = (count) => `/lotto/topWinners?count=${count}&format=JSON&language=en`;
let getTickets = (msisdn) => `/lotto/tickets?format=json&msisdn=${msisdn}`;
let walletTransactionApi = (clientId) => `/wallet/getTransactionList?accessChannel=WEB&clientId=${clientId}&format=json&language=en&walletType=REGULAR`;
let getClientInfo = (clientId, msisdn) => `/cas/getClientInfo?clientId=${clientId}&format=JSON&language=en&msisdn=${msisdn}&withStatistics=true`;
let getClientAdditionalInfo = (clientId) => `/cas/getListAdditionalInfoPerClient?clientId=${clientId}&format=JSON&language=en`;
let editProfileApi = (clientId) => `/cas/updateClientInfo?clientId=${clientId}&format=JSON&language=en`;
let getLatestResult = "/lotto/drawResults?format=json&pageSize=3&pageNumber=0";
let updateClientAdditionalInfo = (clientId) => `/cas/updateClientAdditionalInfo?accessChannel=WEB&clientId=${clientId}&format=JSON&language=en`;
let updateEmail = (action, email, clientId) => `/cas/emailUtility?action=${action}&email=${email}&clientId=${clientId}&format="json"&accessChanel="WEB"`;
let forgotPasswordApi = '/cas/confirmForgetPasswordOTP?format=json&language=en';
let drawResultsApi = (drawId, drawName, month, year, pageSize) => `/lotto/drawResults?${drawId ? `drawId=${drawId}` : ''}&${drawName ? `drawType=${drawName}` : ''}&${month ? `month=${month}` : ''}&${year ? `year=${year}` : ''}&format=json&language=en&pageNumber=0&${pageSize ? `pageSize=${pageSize}` : ''}`;
let sellTicket = (clientId) => `/lotto/sellTicket?clientId=${clientId}&format=json`;
let ticketSummary = (amount,drawName,numbers,variation) => `/lotto/ticketSummary?amount=${amount}&drawName=${drawName}&numbers=${numbers}&variation=${variation}&format=JSON&language=en`

//psb
let momoCashoutApi = (amount, clientId, msisdn) => `/wallet/cashout?accessChannel=WEB&amount=${amount}&format=json&language=en&clientId=${clientId}&msisdn=${msisdn}`;
let momoDepositApi = (amount, clientId, msisdn) => `/wallet/depositToWallet?accessChannel=WEB&amount=${amount}&clientId=${clientId}&accessChanel=WEB&msisdn=${msisdn}&format=json&language=en`;

//interswitch
let requestDepositInterswitch = (amount, msisdn) => `/interswitch/transfer/depositRequest?amount=${amount}&msisdn=${msisdn}`;
let interswitchDepositStatus = (paymentRef, depositRef) => `/interswitch/transfer/depositStatus?paymentReference=${paymentRef}&depositReference=${depositRef}`;
let interswitchBankCodeList = '/interswitch/transfer/bankCodeList';
let interswitchVerifyAccount = (bankCode, accountNumber) => `/interswitch/transfer/verifyAccount?bankCode=${bankCode}&accountNumber=${accountNumber}`;
let interswitchInitWithdraw = (msisdn, amount, accountNumber) => `/interswitch/transfer/initWithdraw?msisdn=${msisdn}&amount=${amount}&accountNumber=${accountNumber}`;
let interswitchFinaliseWithdraw = (withdrawReference, otpCode) => `/interswitch/transfer/finaliseWithdraw?withdrawReference=${withdrawReference}&otpCode=${otpCode}`;
let interswitchDepositRequest = (msisdn, amount) => `interswitch/transfer/depositRequest?msisdn=${msisdn}&amount=${amount}`;

//providus
let providusCheckAccountExists = (msisdn) => `/providus/api/check-account-exists?msisdn=${msisdn}`;
let providusRequestAccountCreation = '/providus/api/request-account-creation';
let providusValidateAccountCreation = '/providus/api/validate-account-creation';
let providusBankList = '/providus/api/bank';
let providusCheckAccountName = (accountNumber, amount, bankName, beneficiaryBank, msisdn) => `/providus/api/check-account-name?accountNumber=${accountNumber}&amount=${amount}&bankName=${bankName}&beneficiaryBank=${beneficiaryBank}&msisdn=${msisdn}`;
let providusWithdrawalExternalBank = (benAccountName, benAccountNumber, code, msisdn) => `/providus/api/withdraw-external-bank?beneficiaryAccountName=${benAccountName}&beneficiaryAccountNumber=${benAccountNumber}&code=${code}&msisdn=${msisdn}`


// opay
let getBanksListApi = '/opay/getBankList';
let bankTransferPaymentApi = '/opay/InitiateBankTransferPayment';
let bankUSSDPaymentApi = '/opay/initiateBankUssdPayment';
let bankAccountPaymentApi = '/opay/initiateBankAccountPayment';
let otpPaymentApi = '/opay/inputOtp';
let OPayWalletPaymentApi = '/opay/initiateOPAYWalletPayment';
let expressCheckoutPaymentApi = '/opay/expressCheckout';
let OPayCashoutApi = '/opay/initiateCashout';

//paystack
let initPaymentTempBank = (amount, customerEmail, msisdn, token) => `/connPaystack/initPaymentTempBank?language=en&accessChannel=WEB&amount=${amount}&customerEmail=${customerEmail}&msisdn=${msisdn}&token=${token}`;
let getBankListUSSD = '/connPaystack/getBankListUSSD?language=en&format=json';
let initPaymentUssd = (amount, bankType, customerEmail, msisdn, token) => `/connPaystack/initPaymentUssd?language=en&accessChannel=WEB&amount=${amount}&bankType=${bankType}&customerEmail=${customerEmail}&msisdn=${msisdn}&token=${token}`
let getBankList = '/connPaystack/getBankList?language=en&format=json&payWithBank=false';
let initPaymentWithBank = (amount, bankAccountNumber, bankCode, customerEmail, msisdn, token) => `/connPaystack/initPaymentBank?language=en&accessChannel=WEB&amount=${amount}&bankAccountNumber=${bankAccountNumber}&bankCode=${bankCode}&customerEmail=${customerEmail}&format=json&msisdn=${msisdn}&token=${token}`
let initPaymentWithPopup = (amount,customerEmail,msisdn,token) => `/connPaystack/initPaymentPopup?language=en&format=json&accessChannel=WEB&amount=${amount}&customerEmail=${customerEmail}&msisdn=${msisdn}&token=${token}`;
let initPaymentWithRedirection = (amount,customerEmail,msisdn,token) => `/connPaystack/initPaymentRedirect?amount=${amount}&customerEmail=${customerEmail}&msisdn=${msisdn}&token=${token}&accessChanel=WEB&language=en&format=json`;
let paymentWebHookVerify = (msisdn,paymentRef) => `/connPaystack/verify?language=en&accessChannel=WEB&format=json&msisdn=${msisdn}&paymentRef=${paymentRef}`;
let getRecipientsList = (msisdn) => `/connPaystack/getRecipientList?language=en&accessChannel=WEB&format=json&msisdn=${msisdn}`;
let initCashout = (accountBankCode, accountNo, amount, msisdn, recipientCode, token) => `/connPaystack/initCashout?language=en&accessChannel=WEB&accountBankCode=${accountBankCode}&accountNo=${accountNo}&amount=${amount}&format=json&msisdn=${msisdn}&recipientCode=${recipientCode}&token=${token}`;
let validateOtp = (otpCode,paymentRef,token) => `/connPaystack/validateOtp?language=en&accessChannel=WEB&format=json&otpCode=${otpCode}&paymentRef=${paymentRef}&token=${token}`;

// palmpay
let palmpayCheckTransStatus = (msisdn, transactionID) => `/connPalmPay/checkTransactionStatus?language=en&accessChannel=WEB&format=json&msisdn=${msisdn}&transactionID=${transactionID}`;
let palmpayGetBankList = '/connPalmPay/getBankList?language=en&format=json';
let palmpayInitCashout = (orderId, otpCode) => `/connPalmPay/initiateCashout?language=en&accessChannel=WEB&format=json&orderId=${orderId}&otpCode=${otpCode}`;
let palmpayInitCheckoutDeposit = (amount, msisdn, returnUrl) => `/connPalmPay/initiateCheckoutDeposit?language=en&accessChannel=WEB&amount=${amount}&format=json&msisdn=${msisdn}&returnUrl=${returnUrl}`;
let palmpayVerifyBankAccount = (accountNo, amount, bankCode, msisdn) => `/connPalmPay/verifyBankAccount?language=en&accessChannel=WEB&accountNo=${accountNo}&amount=${amount}&bankCode=${bankCode}&format=json&msisdn=${msisdn}`;

// Phoenix
// let phoenixEventCall = (eventName, phoenixClickId) => `https://adwh.mywkd.com/thirdparty?event_name=${eventName}&phx_click_id=${phoenixClickId}`;

export {
    refreshTokenApi, userLogin, userLogout, registerUser, walletApi, contactUsApi, validatePromoApi,
    activeDrawsApi, resendOTP, verifyMsisdnApi, getOperators, buyTicket, getBanners, topWinners,
    walletTransactionApi, getTickets, getClientInfo, editProfileApi, getLatestResult, momoDepositApi,
    getClientAdditionalInfo, updateClientAdditionalInfo, requestDepositInterswitch, interswitchDepositStatus, momoCashoutApi, updateEmail,
    interswitchBankCodeList, interswitchVerifyAccount, interswitchInitWithdraw, interswitchFinaliseWithdraw, interswitchDepositRequest,
    providusCheckAccountExists, providusRequestAccountCreation, providusValidateAccountCreation, providusBankList, providusCheckAccountName, providusWithdrawalExternalBank,
    palmpayCheckTransStatus, palmpayGetBankList, palmpayInitCashout, palmpayInitCheckoutDeposit, palmpayVerifyBankAccount,
    forgotPasswordApi, getBanksListApi, bankTransferPaymentApi, bankUSSDPaymentApi, bankAccountPaymentApi, otpPaymentApi, OPayWalletPaymentApi, expressCheckoutPaymentApi, OPayCashoutApi,
    drawResultsApi, initPaymentTempBank, getBankListUSSD, initPaymentUssd, getBankList, initPaymentWithBank, initPaymentWithPopup, initPaymentWithRedirection, paymentWebHookVerify, getRecipientsList, initCashout, validateOtp, sellTicket, ticketSummary, getArticles,
    // phoenixEventCall
};