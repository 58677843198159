import axios from 'axios';
import moment from 'moment/moment';
import Loader from '../Loader/Loader';
import React, { useEffect, useState } from 'react'
import InputField from '../InputField/InputField';
import classes from './UserContainers.module.scss';
import { usePopup } from '../../utils/PopupContext';
import { useDispatch, useSelector } from 'react-redux';
import { auth, register } from '../../store/authSlice';
import Returnmessage from '../Returnmessage/Returnmessage';
import { getOperators, validatePromoApi } from '../../utils/Api';
import Tooltip from '../ToolTip/ToolTip';

export default function SignInSignUp({ type }) {
    const [dob, setDob] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState(null);
    const [loader, setLoader] = useState(null);
    const [password, setPassword] = useState("");
    const [operator, setOperator] = useState('');
    const [username, setUsername] = useState("");
    const [Active, setActive] = useState('signIn');
    const [isMobile, setIsMobile] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [operatorList, setOperatorList] = useState([]);
    const [returnMessage, setReturnMessage] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [promoValidated, setPromoValidated] = useState(null);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const dispatch = useDispatch();

    const loaderAct = useSelector((state) => state.auth.loader2);
    const loginLoader = useSelector(state => state.auth.loader);
    const regMessage = useSelector((state) => state.auth.regMessage);
    const loginMessage = useSelector(state => state.auth.loginMessage);

    const { handlePopupToggle, closePopup } = usePopup();

    const operatorLabelMap = {
        mtn: "MTN",
        airtel: "Airtel",
        GLO: "Glo",
        NINE_MOBILE: "9mobile",
    };

    const initiateLogin = (e) => {
        e.preventDefault();
        if (username && loginPassword !== "") dispatch(auth(username, loginPassword, closePopup, handlePopupToggle));
    }

    const handleChange = (event) => {
        setOperator(event.target.value);
    };

    const checkPromoValidation = () => {
        if (referralCode) {
            setLoader(<Loader />);
            axios
                .get(validatePromoApi(referralCode))
                .then((response) => {
                    setLoader(null);
                    if (response.data.status === 0) {
                        setPromoValidated(true);
                        setStatus(0);
                    }
                    else {
                        setPromoValidated(false);
                        setStatus(1);
                        setReturnMessage('Invalid promo code');
                    }
                })
                .catch(() => {
                    setStatus(1);
                    setReturnMessage('Error validating code, try again later');
                    setPromoValidated(false);
                })
        }
    }

    const submitRegistration = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setReturnMessage('Password and Confirm Password do not match');
            setStatus(1);
        }
        else if (password.length < 6) {
            setReturnMessage('Password should be a minimum of 6 characters long');
            setStatus(1);
        }
        else if (referralCode && promoValidated) {
            dispatch(register(
                '234' + mobileNumber,
                operator,
                email,
                password,
                dob,
                referralCode,
                handlePopupToggle,
                termsAndConditions
            ));
        }
        else {
            dispatch(register(
                '234' + mobileNumber,
                operator,
                email,
                password,
                moment(new Date(dob)).format('MM/DD/YYYY'),
                referralCode,
                handlePopupToggle,
                termsAndConditions
            ));
        }
    };

    useEffect(() => {
        axios.get(getOperators)
            .then((response) => {
                if (response.data.status === 0) {
                    const transformedOperators = response.data.name.map((operator) => ({
                        label: operatorLabelMap[operator] || operator,
                        value: operator,
                    }));
                    setOperatorList(transformedOperators);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        const checkScreenSize = () => setIsMobile(window.innerWidth <= 986);
        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    return (
        <div className='overlay'>
            <div className={`${classes.auth_cnt} ${classes[type]}`}>
                <div className={classes.content}>
                    <div className={classes.header}>
                       <div className={classes.toggle_btns}>
                        <button className={classes.toggle_item + ' ' + [Active === 'signIn' ? classes.toggle_item_active : '']} onClick={() => setActive('signIn')}>sign in</button>
                        <button className={classes.toggle_item + ' ' + [Active === 'signUp' ? classes.toggle_item_active : '']} onClick={() => setActive('signUp')}>sign up</button>
                       </div>
                        <div className={classes.close_btn} onClick={closePopup}>
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                            <i className={classes.close_x} />
                        </div>
                    </div>

                    <div className={classes.auth_body}>
                        <form className={classes.left_section + ' ' +[isMobile && Active === 'signIn' ? classes.activate_section : '']} onSubmit={initiateLogin}>
                            <span className={classes.title}>sign in</span>
                            <InputField required={true} Ismobile={true} label="mobile number *" type="number" placeholder="24 5202 020" inputValueProp={username} onChangeInput={setUsername} />
                            <InputField required={true} label="password *" type="password" placeholder="Enter your password ***" inputValueProp={loginPassword} onChangeInput={setLoginPassword} />
                            <div className={classes.action_cnt2}>
                                <span className={classes.action_label}>Forgot my password,</span>
                                <button className={classes.action_btn} onClick={() => handlePopupToggle('resetOtp_pass')} type='button'>reset password</button>
                            </div>
                            <div className={classes.action_cnt_sm}>
                                <button className={classes.sign_in_btn} type='submit'>{loginLoader ? <Loader /> : 'sign in'}</button>
                            </div>
                            {loginMessage && <Returnmessage type='error' message={loginMessage} />}
                        </form>
                        <form className={classes.right_section + ' ' + [isMobile && Active === 'signUp' ? classes.activate_section : '']} onSubmit={submitRegistration}>
                            <span className={classes.title}>sign up</span>
                            <InputField required={true} Ismobile={true} label="mobile number *" type="number" placeholder="24 5202 020" inputValueProp={mobileNumber} onChangeInput={setMobileNumber} />
                            <label className={classes.input_label}>Operator</label>
                            <div className={classes.input_wrapper}>
                                <select
                                    value={operator}
                                    onChange={handleChange}
                                    className={classes.select_input}
                                    required={true}
                                >
                                    <option value="">Select an option</option>
                                    {operatorList.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <InputField required={true} label="email *" type="email" placeholder="Enter your email" inputValueProp={email} onChangeInput={setEmail} />
                            <InputField required={true} label={<span>password *
                                <Tooltip message="Your password should be at least 6 characters long and includes at least one uppercase" />
                            </span>} type="password" placeholder="Enter your password ***" inputValueProp={password} onChangeInput={setPassword} />
                            <InputField required={true} label="confirm password *" type="password" placeholder="Enter your password ***" inputValueProp={confirmPassword} onChangeInput={setConfirmPassword} />
                            <InputField required={true} label="date of birth *" type="date" placeholder="DD/MM/YYYY" inputValueProp={dob} onChangeInput={setDob} />
                            <InputField required={false} label="referral code" type="text" placeholder="Enter referral code" inputValueProp={referralCode} onChangeInput={setReferralCode} onblur={checkPromoValidation} />
                            <div className={`${classes.inputGroup} ${classes.terms}`}>
                                <input required type="checkbox" className={classes.inputCheckBox} onChange={e => { setTermsAndConditions(e.target.checked) }} />
                                <label className={`${classes.inputLabel} ${classes.terms}`}>By creating an account, you agree to our <a href="/terms" className={classes.link}>Terms & Conditions</a> and confirm that you are at least 18 years old or over and all information given is true*</label>
                            </div>
                            <div className={classes.action_cnt}>
                                <button className='button button--btn-primary-md' type='submit'>{loaderAct ? <Loader /> : 'create account'}</button>
                            </div>
                            {loader}
                            {regMessage ? <Returnmessage type='error' message={regMessage} /> : status === 0 && returnMessage !== '' ? <Returnmessage type='success' message={returnMessage} /> : status === 1 ? <Returnmessage type='error' message={returnMessage} /> : ''}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
