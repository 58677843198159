import { createSlice } from "@reduxjs/toolkit";
import ApiClients from "../utils/ApiClients";
import { walletTransactionApi, getTickets, getClientInfo, getClientAdditionalInfo } from '../utils/Api';
import CONSTANTS from "../utils/CONSTANTS";

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    loading: null,
    transactions: [],
    totalTransactions: [],
    tickets: [],
    userInfo: null,
    userAdditionalInfo: null,
    ussdPaystackShortCode: null,
    paystackAmount: null,
    paystackAccountNumber: null,
    paystackAccountName: null,
    paystackBankName: null,
    totalTickets: null,
    paymentReference: null,
  },
  reducers: {
    toggleLoading: (state, action) => {
      state.loading = action.payload;
    },
    fetchTransactionsSuccess: (state, action) => {
      state.transactions = action.payload;
    },
    fetchTotalTransactions: (state, action) => {
      state.totalTransactions = action.payload;
    },
    fetchTicketsSuccess: (state, action) => {
      state.tickets = action.payload;
    },
    fetchTotalTicketsSuccess: (state, action) => {
      state.totalTickets = action.payload;
    },
    saveUserData: (state, action) => {
      state.userInfo = action.payload;
    },
    saveUserAdditionalData: (state, action) => {
      state.userAdditionalInfo = action.payload;
    },
    saveUssdShortCode: (state, action) => {
      state.ussdPaystackShortCode = action.payload.shortcode;
      state.paystackAmount = action.payload.amount;
    },
    saveTempBankInfo: (state, action) => {
      state.paystackAccountNumber = action.payload.accountNumber;
      state.paystackAmount = action.payload.amount;
      state.paystackAccountName = action.payload.accountName;
      state.paystackBankName = action.payload.bankName;
    },
    saveWithdrawInfo: (state, action) => {
      state.paymentReference = action.payload;
    }
  },
});

export const { toggleLoading, fetchTransactionsSuccess, fetchTotalTransactions, fetchTicketsSuccess, saveUserData, saveUserAdditionalData, saveUssdShortCode, saveTempBankInfo, fetchTotalTicketsSuccess, saveWithdrawInfo } = walletSlice.actions;

const buildQueryString = (params) => {
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((val) => `${key}=${encodeURIComponent(val)}`)
          .join("&");
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");
};

export const getWalletTransactions = ({ clientId, limit = null, page = null }) => {
  return (dispatch) => {
    dispatch(toggleLoading(true));

    const params = {
      clientId,
      limit,
      page
    }
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value != null)
    );
    const queryString = buildQueryString(filteredParams);
    const baseUrl = walletTransactionApi(clientId);
    const url = `${baseUrl}${queryString ? "&" : ""}${queryString}`;
    ApiClients.get(url)
      .then((response) => {
        dispatch(toggleLoading(false));
        if (response.data.status === "0") {
          dispatch(fetchTransactionsSuccess(response.data.transactions.map(({ amount, transactionType, updatedBalance, externalProviderName, dateCreated }) => ({
            amount,
            transactionType,
            updatedBalance,
            externalProviderName,
            dateCreated
          })) || []));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export const getUserTickets = ({ pageSize = null, pageNumber = null }) => {
  return (dispatch) => {
    dispatch(toggleLoading(true));

    const params = {
      pageSize,
      pageNumber
    }
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value != null)
    );
    const queryString = buildQueryString(filteredParams);
    const baseUrl = getTickets(localStorage.getItem('msisdn'));
    const url = `${baseUrl}${queryString ? "&" : ""}${queryString}`;
    ApiClients.get(url)
      .then((response) => {
        dispatch(toggleLoading(false));
        if (response.data.status === "0") {
          dispatch(fetchTicketsSuccess(response.data.tickets));
          dispatch(fetchTotalTicketsSuccess(response.data.totalTickets))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export const getUserData = () => {
  return (dispatch) => {
    ApiClients.get(getClientInfo(localStorage.getItem('clientId'), localStorage.getItem('msisdn'))).then((res) => {
      if (res.data.status === 0) {
        dispatch(saveUserData(res.data));
        res?.data?.birthDate ? localStorage.setItem(CONSTANTS.BIRTH_DATE, res.data.birthDate) : localStorage.removeItem(CONSTANTS.BIRTH_DATE);
      }
    }).catch((err) => {
      console.error(err);
    })
  }
}

export const getUserAdditionalData = () => {
  return (dispatch) => {
    return ApiClients.get(getClientAdditionalInfo(localStorage.getItem('clientId')))
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(saveUserAdditionalData(res.data.additionalInfo));

          const hasFirstName = res.data.additionalInfo.find((info) => info.key === "PFName");
          const hasLastName = res.data.additionalInfo.find((info) => info.key === "PLName");
          const hasBvn = res.data.additionalInfo.find((info) => info.key === "BVN");
          const firstName = hasFirstName ? hasFirstName.value : null;
          const lastName = hasLastName ? hasLastName.value : null;
          if (hasFirstName && hasLastName) {
            localStorage.setItem('fullName', firstName + ' ' + lastName);
          }
          return hasFirstName && hasLastName && hasBvn;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };
};


export default walletSlice.reducer;

