import React, { useEffect, useState } from 'react';
import classes from './InputField.module.scss';
import pass_eye from '../../assets/Icons/eye_pass.svg';
import nigeria_flag from '../../assets/Account-cards/nigeria_flag.svg';

export default function InputField({ label, type, placeholder, inputValueProp, error, readOnly, required, onChangeInput, width, onblur, Ismobile, currency, option, maxLength }) {
    const [inputType, setInputType] = useState(type);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setInputValue(inputValueProp || "");
    }, [inputValueProp]);

    const togglePasswordVisibility = () => {
        setInputType(prevType => (prevType === "password" ? "text" : "password"));
    };

    const handleInputChange = (e) => {
        const value = maxLength ? e.target.value.slice(0, maxLength) : e.target.value;
        setInputValue(value);
        if (onChangeInput) {
            onChangeInput(value);
        }
    };

    return (
        <div className={`${classes.input_field_container} ${classes[option]}`} style={{ width: width }}>
            <label htmlFor="email" className={classes.input_label}>{label}</label>
            <div className={classes.input_wrapper} >
                {type === "number" && Ismobile && (
                    <div className={classes.country_cnt}>
                        <img
                            src={nigeria_flag}
                            alt='country flag'
                            className={classes.country_flag}
                        />
                        <span className={classes.country_code}>+234</span>
                    </div>
                )}
                {type === 'textarea' ?
                    <textarea
                        placeholder={placeholder}
                        value={inputValue}
                        required={required}
                        onChange={handleInputChange}
                        className={`${classes.input_field} ${classes.textArea}`}
                    /> :
                    <>
                        <input
                            required={required}
                            onChange={handleInputChange}
                            onBlur={onblur}
                            value={inputValue}
                            disabled={readOnly}
                            type={inputType}
                            className={classes.input_field}
                            placeholder={placeholder}
                        />
                        {currency && <span className={classes.web_currency}>{process.env.REACT_APP_CURRENCY}</span>}
                    </>
                }

                {type === "password" && (
                    <img
                        src={pass_eye}
                        alt="Toggle password visibility"
                        className={classes.eye_icon}
                        onClick={togglePasswordVisibility}
                        loading="lazy"
                    />
                )}
            </div>
        </div>
    );
};
