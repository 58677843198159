// src/contexts/PopupContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
    const [activePopup, setActivePopup] = useState(null);
    const location = useLocation();

    const handlePopupToggle = (popup) => {
        setActivePopup(() => popup);
        document.body.classList.add('mobileMenuOpen');
    };

    const closePopup = () => {
        setActivePopup(null);
        document.body.classList.remove('mobileMenuOpen');
    };

    useEffect(() => {
        // Check if the URL has the query parameter to open signup popup
        const params = new URLSearchParams(location.search);
        if (params.get('popup') === 'signup') {
            handlePopupToggle("signInSignUp");
        }
    }, [location.search]);

    return (
        <PopupContext.Provider value={{ activePopup, handlePopupToggle, closePopup }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopup = () => useContext(PopupContext);
