import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import classes from './Articles.module.scss';
import { useLocation } from 'react-router-dom';
import { getArticles } from '../../../utils/Api';
import axios from 'axios';
import GameCarousel from '../../../components/GameList/GameCarousel';

export default function Articles() {
  const location = useLocation();
  const [articleId, setArticleId] = useState(null);
  const [articleData, setArticleData] = useState(null);
  const [title,setTitle] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('articleId');
    const title = queryParams.get('title');
    setArticleId(id);
    setTitle(title)
  }, [location]);

  useEffect(() => {
    if (articleId) {
      axios.get(getArticles(articleId)).then((res) => {
        if (res.data.status === 0) {
          setArticleData(res.data.articles[0]);
        }
      }).catch((res) => {
        console.log(res);
      })
    }
  }, [articleId])

  return (
    <>
      <Header />
      <div className={classes.media_bg}>
        <div className={classes.promo_text_cnt}>
          <span className={classes.promo_text}>{title && title}</span>
        </div>
        <img className={classes.media_img} src={articleData?.thumbnailImage} alt="" loading="lazy" />
      </div>
      <div className={classes.articles_cnt}>
        {articleData ? (
          <div className={classes.article_wrapper}>
            <h1 className={classes.article_title}>{articleData.title}</h1>
            <div
              className={classes.article_content}
              dangerouslySetInnerHTML={{ __html: articleData.content }}
            ></div>
          </div>
        ) : (
          <p>Loading article...</p>
        )}
        <div className={classes.wrapper_articles}>
        <GameCarousel type="articlePage"/>
        </div>
      </div>
      <Footer />
    </>
  )
}
